exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-project-js-content-file-path-src-content-induo-mdx": () => import("./../../../src/pages/project.js?__contentFilePath=/opt/buildhome/repo/src/content/induo.mdx" /* webpackChunkName: "component---src-pages-project-js-content-file-path-src-content-induo-mdx" */),
  "component---src-pages-project-js-content-file-path-src-content-my-wellness-mdx": () => import("./../../../src/pages/project.js?__contentFilePath=/opt/buildhome/repo/src/content/my-wellness.mdx" /* webpackChunkName: "component---src-pages-project-js-content-file-path-src-content-my-wellness-mdx" */),
  "component---src-pages-project-js-content-file-path-src-content-revive-mdx": () => import("./../../../src/pages/project.js?__contentFilePath=/opt/buildhome/repo/src/content/revive.mdx" /* webpackChunkName: "component---src-pages-project-js-content-file-path-src-content-revive-mdx" */),
  "component---src-pages-project-js-content-file-path-src-content-vizgu-mdx": () => import("./../../../src/pages/project.js?__contentFilePath=/opt/buildhome/repo/src/content/vizgu.mdx" /* webpackChunkName: "component---src-pages-project-js-content-file-path-src-content-vizgu-mdx" */),
  "component---src-pages-project-js-content-file-path-src-content-we-do-wood-mdx": () => import("./../../../src/pages/project.js?__contentFilePath=/opt/buildhome/repo/src/content/we-do-wood.mdx" /* webpackChunkName: "component---src-pages-project-js-content-file-path-src-content-we-do-wood-mdx" */),
  "component---src-pages-project-js-content-file-path-src-content-workdash-mdx": () => import("./../../../src/pages/project.js?__contentFilePath=/opt/buildhome/repo/src/content/workdash.mdx" /* webpackChunkName: "component---src-pages-project-js-content-file-path-src-content-workdash-mdx" */)
}

